<template>
  <header/>
  <div class="container">
      <RouterView/>
  </div>
</template>

<script setup>
</script>

<style>
</style>
