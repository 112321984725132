import Home_page from './components/home_page.vue';
import Auth_page from './components/auth/auth_page.vue';
import Login_page from './components/auth/login_page.vue';
import Register_page from './components/auth/register_page.vue';

import Not_found from './components/other/not_found.vue';

export const routes = [
  { path: '/', component: Home_page },
  { path: '/auth', component: Auth_page },
  { path: '/auth/sign-in', component: Login_page },
  { path: '/auth/sign-up', component: Register_page },

  // Catch-all qui affiche la page 404
  { path: '/:pathMatch(.*)*', name: 'NotFound', component: Not_found }
];